import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "explanation";

export default {
  find(runningSubtestId, questionId) {
    return api().get(
      `${moduleName}/running_subtest/${runningSubtestId}/question/${questionId}/find`,
      {
        headers: authHeader()
      }
    );
  }
};
