<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" order-md="last">
        <v-sheet class="pa-4" min-height="100" rounded="lg" outlined>
          <v-row>
            <v-col
              v-for="question in questions"
              :key="question.position"
              md="3"
            >
              <v-btn
                :color="
                  question.position === questionNumber
                    ? 'success'
                    : question.isAnswered
                    ? 'primary'
                    : 'default'
                "
                @click="loadQuestion(question.questionId, question.position)"
              >
                {{ question.position }}
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="8" order-md="first">
        <v-sheet min-height="200" rounded="lg" outlined>
          <v-row>
            <v-col cols="12"
              ><v-card-title
                >{{ subtest.test_name }} - {{ subtest.subject_name }} •
                {{ this.questionPosition }}</v-card-title
              ></v-col
            >
          </v-row>
          <v-divider />
          <div class="pa-4">
            <span v-html="question.question"></span>
            <v-divider class="mt-4" />
            <v-radio-group v-model="selectedAnswer" hide-details readonly>
              <v-radio
                v-for="answer in question.answers"
                :key="answer.id"
                :value="answer.id"
                :class="`pa-2 ${answer.id === correctAnswer.id ? 'radio' : ''}`"
              >
                <template v-slot:label>
                  <div style="width: 100%" class="d-flex align-center">
                    <div
                      v-html="answer.answer"
                      :class="`${
                        answer.id === correctAnswer.id ? 'white--text' : ''
                      }`"
                    ></div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div class="pa-4" v-if="explanation !== null">
            <v-divider />
            <h3 class="mt-4">Explanation:</h3>
            <div v-html="explanation"></div>
          </div>
          <v-divider />
          <v-card-actions>
            <v-btn
              v-if="questionNumber > 1"
              @click="
                loadQuestion(
                  findQuestionIdByPosition(questionNumber - 1),
                  questionNumber - 1
                )
              "
              color="primary"
            >
              <v-icon left>mdi-menu-left</v-icon>
              Previous Question
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="questionNumber !== questions.length"
              @click="
                questionNumber === questions.length
                  ? confirmendSubtestDialog()
                  : loadQuestion(
                      findQuestionIdByPosition(questionNumber + 1),
                      questionNumber + 1
                    )
              "
              :color="questionNumber === questions.length ? 'error' : 'primary'"
            >
              Next Question
              <v-icon v-if="questionNumber !== questions.length" right
                >mdi-menu-right</v-icon
              >
            </v-btn>
          </v-card-actions>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="css" scoped>
@import "~quill/dist/quill.snow.css";
.pointer-cursor:hover {
  cursor: pointer;
}
.radio {
  background-color: #4caf50;
  border-radius: 10px;
  padding: 8px !important;
  color: white;
}
</style>

<script>
import katex from "katex";
import { mapActions } from "vuex";
import ExplanationService from "@/services/explanation";
import SubtestService from "@/services/subtest";
import "katex/dist/katex.min.css";

export default {
  name: "Review.Subtest",
  data() {
    return {
      test: {
        name: "",
        testEnd: ""
      },
      questions: [],
      question: {
        question: ""
      },
      questionNumber: 1,
      selectedAnswer: undefined,
      subtest: {
        name: "",
        subject_name: ""
      },
      runningTestId: "",
      correctAnswer: {},
      explanation: null
    };
  },
  computed: {
    dataId() {
      return this.$route.params.id;
    },
    questionPosition() {
      return `${this.questionNumber}/${this.questions.length}`;
    }
  },
  mounted() {
    window.katex = katex;
  },
  async created() {
    this.setNavbarTitle("Subtest");
    await Promise.allSettled([
      this.findRunningSubtestById(this.dataId),
      this.findTestInformation(this.dataId),
      this.findRunningSubtest(),
      this.findRunningSubtestQuestions().then(() =>
        this.loadQuestion(
          this.findQuestionIdByPosition(this.questionNumber),
          this.questionNumber
        )
      )
    ]);
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async findRunningSubtest() {
      const fetchRunningSubest = await SubtestService.findRunningSubtest(
        this.dataId
      );
      const subtest = fetchRunningSubest.data.data;
      this.subtest = subtest;
    },
    async findRunningSubtestQuestions() {
      const questions = await SubtestService.findRunningSubtestQuestions(
        this.dataId
      );
      this.questions = questions.data.data;
    },
    async loadQuestion(questionId, position) {
      this.questionNumber = position;
      this.question = { question: "" };
      this.explanation = null;
      this.selectedAnswer = undefined;
      const question = await SubtestService.findQuestion(questionId, {
        running_subtest_id: this.dataId
      });
      const correctAnswer = await SubtestService.correctAnswer(
        this.dataId,
        questionId
      );
      await this.findExplanation(
        this.dataId,
        this.findQuestionIdByPosition(this.questionNumber)
      );
      this.question = question.data.data.question;
      const selectedAnswer =
        question.data.data.testAnswer?.answerId || undefined;
      this.selectedAnswer = selectedAnswer;
      this.correctAnswer = correctAnswer.data.data;
    },
    findQuestionIdByPosition(position) {
      return this.questions.find(question => question.position === position)
        .questionId;
    },
    async findRunningSubtestById(runningSubtestId) {
      const runningSubtest = await SubtestService.runningSubtestById(
        runningSubtestId
      );
      this.runningTestId = runningSubtest.data.data.runningTestId;
    },
    async findTestInformation(runningSubtestId) {
      const test = await SubtestService.testInformation(runningSubtestId);
      this.test = test.data.data;
    },
    testAvailabilityColor(status) {
      switch (status) {
        case "not yet started":
          return "default";
        case "started":
          return "primary";
        case "ended":
          return "error";
        case "ongoing":
          return "warning";
        case "done":
          return "success";
      }
    },
    async findExplanation(runningSubtestId, questionId) {
      const explanation = await ExplanationService.find(
        runningSubtestId,
        questionId
      );
      this.explanation =
        explanation.data.data === null
          ? null
          : explanation.data.data.explanation;
    }
  }
};
</script>
